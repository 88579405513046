import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" height="50" width="210">
    <title>Loader Logo</title>
    <g>
      <path
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 15,5 h 180 a 10,10 0 0 1 10,10 v 20 a 10,10 0 0 1 -10,10 h -180 a 10,10 0 0 1 -10,-10 v -20 a 10,10 0 0 1 10,-10 z"
      />
    </g>
    <text
      id="B"
      x="50%"
      y="50%"
      fill="currentColor"
      dominantBaseline="middle"
      textAnchor="middle"
      fontSize="20px"
      fontFamily="SF Mono, Fira Code, Fira Mono, Roboto Mono, monospace"
    >
      davebanguilan.
    </text>
  </svg>
);

export default IconLoader;
